<template>
  <div>
    <b-tabs
      pills
      fill
    >
      <b-tab active>
        <template #title>
          <feather-icon icon="LifeBuoyIcon" />
          <span>Talep Bilgileri</span>
        </template>
        <validation-observer ref="simpleRules">
          <support-view
            :submit-form="submitForm"
            :submit-status="submitStatus"
          />
        </validation-observer>
      </b-tab>
      <b-tab lazy>
        <template #title>
          <feather-icon icon="UserIcon" />
          <span>Müşteri Bilgileri</span>
        </template>
        <customer-info />
        <customer-invoice />
      </b-tab>
      <b-tab lazy>
        <template #title>
          <feather-icon icon="ClockIcon" />
          <span>Müşteri Geçmişi</span>
        </template>
        <customer-history />
      </b-tab>
    </b-tabs>
  </div>
</template>
<script>
import {
  BTabs, BTab,
} from 'bootstrap-vue'
import { ValidationObserver, localize } from 'vee-validate'
import { required } from '@validations'
import CustomerInfo from '@/views/Admin/Customers/View/CustomerInfo.vue'
import CustomerInvoice from '@/views/Admin/Customers/View/CustomerInvoice.vue'
import CustomerHistory from '@/views/Admin/Customers/View/CustomerHistory.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import SupportView from '@/views/Admin/Supports/SupportView.vue'

export default {
  name: 'Add',
  components: {
    BTabs,
    BTab,
    SupportView,
    CustomerInfo,
    CustomerInvoice,
    CustomerHistory,
    ValidationObserver,
  },
  data() {
    return {
      required,
      submitStatus: false,
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['supports/dataItem']
    },
    saveData() {
      return this.$store.getters['supports/dataSaveStatus']
    },
  },
  watch: {
    saveData(val) {
      if (val.status === true) {
        this.showToast({
          variant: 'success', title: 'İşlem Başarılı!', icon: 'CheckIcon', text: val.message,
        })
      } else if (val.status === false) {
        this.showToast({
          variant: 'danger', title: 'İşlem Hatası!', icon: 'XIcon', text: val.message,
        })
      }
    },
  },
  beforeCreate() {
    this.$store.commit('supports/RESET_DATA_ITEM')
  },
  created() {
    this.getData()
    localize('tr')
  },
  methods: {
    getData() {
      this.$store.dispatch('supports/getDataItem', this.$route.params.id)
        .then(response => {
          if (response.id_customers) {
            this.getCustomer(response.id_customers)
          }
        })
    },
    getCustomer(id) {
      this.$store.dispatch('customers/getDataItem', id)
    },
    submitForm() {
      this.submitStatus = true
      this.$store.dispatch('supports/saveData', this.dataItem)
    },
    showToast(params) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: params.title,
          icon: params.icon,
          text: params.text,
          variant: params.variant,
        },
      })
    },
  },
}
</script>
