<template>
  <b-card
    title="Talep Yanıtları"
  >
    <div
      v-if="lines.length > 0"
      class="mb-2"
    >
      <div
        v-for="line in lines"
        :key="line.id"
      >
        <div
          v-if="line.username"
          class="d-flex align-content-end text-right mb-2"
        >
          <div class="bg-light-success p-1 rounded text-success w-100">
            {{ line.text }}
            <div class="font-weight-light font-small-2">
              {{ line.username }}
            </div>
            <div class="font-small-2">
              {{ moment(line.created).format('llll') }}
            </div>
          </div>
        </div>
        <div
          v-if="line.customer_user"
          class="d-flex d-block align-content-start mb-2"
        >
          <div class="d-block bg-light-danger p-1 w-100 rounded text-danger">
            {{ line.text }}
            <div class="font-weight-light font-small-2">
              {{ line.customer_user }}
            </div>
            <div class="font-small-2">
              {{ moment(line.created).format('llll') }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <b-alert
        show
        variant="info"
      >
        <div class="alert-body text-center">
          <FeatherIcon icon="InfoIcon" />
          Henüz hiç yanıt eklenmemiş.
        </div>
      </b-alert>
    </div>
    <validation-observer
      v-if="['2','3'].includes(dataItem.id_support_statuses)"
      ref="simpleRules"
    >
      <support-answer />
      <b-button
        variant="primary"
        @click="submitForm"
      >
        <FeatherIcon icon="SendIcon" />
        Gönder
      </b-button>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BCard, BButton, BAlert,
} from 'bootstrap-vue'
import SupportAnswer from '@/views/Admin/Supports/elements/SupportAnswer.vue'
import { ValidationObserver, localize } from 'vee-validate'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'SupportLines',
  components: {
    BCard,
    BButton,
    BAlert,
    SupportAnswer,
    ValidationObserver,
  },
  data() {
    return {
      required,
    }
  },
  computed: {
    lines() {
      return this.$store.getters['supportLines/dataList']
    },
    dataItem() {
      return this.$store.getters['supports/dataItem']
    },
    saveData() {
      return this.$store.getters['supportLines/dataSaveStatus']
    },
    answer() {
      return this.$store.getters['supportLines/dataItem']
    },
  },
  watch: {
    saveData(val) {
      if (val.status === true) {
        this.showToast({
          variant: 'success', title: 'İşlem Başarılı!', icon: 'CheckIcon', text: val.message,
        })
        this.getLines()
        this.$store.commit('supportLines/RESET_DATA_ITEM')
        this.$refs.simpleRules.reset()
      } else if (val.status === false) {
        this.showToast({
          variant: 'danger', title: 'İşlem Hatası!', icon: 'XIcon', text: val.message,
        })
      }
    },
  },
  created() {
    this.getLines()
    localize('tr')
  },
  methods: {
    getLines() {
      this.$store.dispatch('supportLines/getDataList', {
        where: {
          'support_lines.id_supports': this.$route.params.id,
        },
      })
    },
    submitForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.answer.id_supports = this.$route.params.id
          this.$store.dispatch('supportLines/saveData', this.answer)
        }
      })
    },
    showToast(params) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: params.title,
          icon: params.icon,
          text: params.text,
          variant: params.variant,
        },
      })
    },
  },
}
</script>
