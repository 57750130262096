<template>
  <div>
    <b-list-group>
      <b-list-group-item
        v-for="(item,index) in taskData.lines"
        :key="item.id"
      >
        <task-line-item
          :data-item="item"
          :data-index="index"
          :refresh-data="refreshData"
        />
      </b-list-group-item>
    </b-list-group>
  </div>
</template>
<script>
import {
  BListGroupItem, BListGroup,
} from 'bootstrap-vue'
import TaskLineItem from '@/views/Admin/Tasks/View/TaskLineItem.vue'

export default {
  name: 'StopwatchCard',
  components: {
    BListGroup,
    BListGroupItem,
    TaskLineItem,
  },
  props: {
    refreshData: {
      type: Function,
      required: true,
    },
  },
  computed: {
    taskData() {
      return this.$store.getters['tasks/dataItem']
    },
  },
}
</script>
