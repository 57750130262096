<template>
  <validation-observer ref="simpleRules">
    <b-row>
      <b-col
        cols="12"
        md="6"
      >
        <task-types />
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <priorities />
      </b-col>
      <b-col
        v-if="detailForm"
        cols="12"
        md="6"
      >
        <start-date />
      </b-col>
      <b-col
        v-if="detailForm"
        cols="12"
        md="6"
      >
        <dead-line />
      </b-col>
      <b-col
        v-if="detailForm"
        cols="12"
      >
        <task-title />
      </b-col>
      <b-col
        v-if="detailForm"
        cols="12"
      >
        <task-text />
      </b-col>
      <b-col>
        <b-button
          variant="primary"
          @click="submitForm"
        >
          <FeatherIcon icon="CheckCircleIcon" />
          Görev Oluştur
        </b-button>
      </b-col>
      <b-col
        cols="12"
        md="auto"
      >
        <b-form-checkbox
          v-model="detailForm"
          switch
        >
          Detaylı Form
        </b-form-checkbox>
      </b-col>
    </b-row>
  </validation-observer>
</template>
<script>
import StartDate from '@/views/Admin/Supports/elements/Tasks/StartDate.vue'
import TaskTypes from '@/views/Admin/Supports/elements/Tasks/TaskTypes.vue'
import TaskText from '@/views/Admin/Supports/elements/Tasks/TaskText.vue'
import TaskTitle from '@/views/Admin/Supports/elements/Tasks/TaskTitle.vue'
import {
  BButton, BCol, BFormCheckbox, BRow,
} from 'bootstrap-vue'
import Priorities from '@/views/Admin/Supports/elements/Tasks/Priorities.vue'
import DeadLine from '@/views/Admin/Supports/elements/Tasks/DeadLine.vue'
import { required } from '@core/utils/validations/validations'
import { ValidationObserver, localize } from 'vee-validate'

export default {
  name: 'TaskForm',
  components: {
    BCol,
    BRow,
    BButton,
    BFormCheckbox,
    DeadLine,
    Priorities,
    TaskTitle,
    TaskText,
    TaskTypes,
    StartDate,
    ValidationObserver,
  },
  props: {
    refreshData: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      required,
      detailForm: false,
    }
  },
  computed: {
    taskData() {
      return this.$store.getters['supports/taskData']
    },
    dataItem() {
      return this.$store.getters['tasks/fastTaskItem']
    },
    supportDataItem() {
      return this.$store.getters['supports/dataItem']
    },
  },
  created() {
    this.getTaskTypes()
    this.getPriorities()
    this.setTaskData()
    localize('tr')
  },
  methods: {
    setTaskData() {
      this.dataItem.title = this.supportDataItem.subject
      this.dataItem.text = this.supportDataItem.text
      this.dataItem.sdate = this.moment().format('YYYY-MM-DD')
      this.dataItem.id_priorities = this.supportDataItem.id_priorities
      this.dataItem.id_projects = this.supportDataItem.id_projects
      this.dataItem.id_supports = this.supportDataItem.id
    },
    getPriorities() {
      this.$store.dispatch('priorities/getDataList', {
        select: [
          'priorities.id AS id',
          'priorities.title AS title',
        ],
      })
    },
    getTaskTypes() {
      this.$store.dispatch('taskTypes/getDataList', {
        select: [
          'task_types.id AS id',
          'task_types.title AS title',
        ],
      })
    },
    submitForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.$store.dispatch('tasks/saveFastData', this.dataItem)
            .then(response => {
              if (response.status) {
                this.refreshData()
              }
            })
        }
      })
    },
  },
}
</script>
