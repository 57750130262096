<template>
  <b-list-group-item>
    <div class="text-primary font-weight-bold">
      {{ itemTitle }}
    </div>
    <div>{{ itemValue }}</div>
  </b-list-group-item>
</template>

<script>
import { BListGroupItem } from 'bootstrap-vue'

export default {
  name: 'ListItem',
  components: {
    BListGroupItem,
  },
  props: {
    itemTitle: {
      type: String,
      default: null,
    },
    itemValue: {
      type: String,
      default: null,
    },
  },
}
</script>
