<template>
  <b-row>
    <b-col
      cols="12"
      md="4"
    >
      <b-card no-body>
        <b-list-group>
          <b-list-group-item>
            <div class="text-primary font-weight-bold">
              Talep Eden
            </div>
            <div>{{ dataItem.customer_user }}</div>
            <div class="text-warning font-small-2">
              {{ dataItem.customer }}
            </div>
          </b-list-group-item>
          <list-item
            item-title="Proje"
            :item-value="dataItem.project"
          />
          <list-item
            item-title="Öncelik Durumu"
            :item-value="dataItem.priority"
          />
          <list-item
            item-title="Talep Durumu"
            :item-value="dataItem.support_status"
          />
          <list-item
            item-title="Talep Tarihi"
            :item-value="moment(dataItem.created).format('llll')"
          />
          <list-item
            item-title="Talep Onay Tarihi"
            :item-value="moment(dataItem.approved).format('llll')"
          />
          <b-list-group-item>
            <support-status />
            <b-button
              variant="danger"
              block
              :disabled="submitStatus"
              @click="submitForm"
            >
              <FeatherIcon icon="SaveIcon" />
              Kaydet
            </b-button>
          </b-list-group-item>
        </b-list-group>
      </b-card>
    </b-col>
    <b-col v-if="dataItem.id">
      <task-card
        v-if="['2','3'].includes(dataItem.id_support_statuses)"
        :refresh-data="getTask"
      />
      <b-card title="Talep Detayı">
        <b-list-group>
          <list-item
            item-title="Konu"
            :item-value="dataItem.subject"
          />
          <list-item
            item-title="Talep"
            :item-value="dataItem.text"
          />
          <b-list-group-item v-if="dataItem.files.length">
            <div class="text-primary font-weight-bold">
              Dosya Eki
            </div>
            <div
              v-for="file in dataItem.files"
              :key="file.id"
              class="text-primary font-small-2 d-inline-flex"
            >
              <b-link
                :href="mediaUrl + '/media/support_files/' +file.filename"
                target="_blank"
                class="mr-1"
              >
                <FeatherIcon icon="PaperclipIcon" />
                Dosya Eki
              </b-link>
            </div>
          </b-list-group-item>
        </b-list-group>
      </b-card>
      <support-lines />
    </b-col>
  </b-row>
</template>
<script>
import {
  BCard, BRow, BCol, BListGroup, BListGroupItem, BButton, BLink,
} from 'bootstrap-vue'
import ListItem from '@/views/Admin/Supports/View/ListItem.vue'
import SupportLines from '@/views/Admin/Supports/View/SupportLines.vue'
import SupportStatus from '@/views/Admin/Supports/elements/SupportStatus.vue'
import TaskCard from '@/views/Admin/Supports/Tasks/TaskCard.vue'

export default {
  name: 'SupportForm',
  components: {
    BCard,
    BRow,
    BCol,
    BListGroup,
    BListGroupItem,
    BButton,
    BLink,
    ListItem,
    SupportLines,
    SupportStatus,
    TaskCard,
  },
  props: {
    submitForm: {
      type: Function,
      required: true,
    },
    submitStatus: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      mediaUrl: this.$store.state.app.baseURL,
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['supports/dataItem']
    },
  },
  created() {
    this.getTask()
  },
  methods: {
    getTask() {
      this.$store.dispatch('tasks/getDataItemCustom', {
        where: {
          'tasks.id_supports': this.$route.params.id,
        },
        array: false,
      })
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
