<template>
  <b-card>
    <template v-if="!taskData">
      <task-form :refresh-data="refreshData" />
    </template>
    <template v-else>
      <stopwatch-card :refresh-data="refreshData" />
    </template>
  </b-card>
</template>
<script>
import {
  BCard,
} from 'bootstrap-vue'
import TaskForm from '@/views/Admin/Supports/Tasks/TaskForm.vue'
import StopwatchCard from '@/views/Admin/Supports/Tasks/StopwatchCard.vue'

export default {
  name: 'TaskCard',
  components: {
    BCard,
    TaskForm,
    StopwatchCard,
  },
  props: {
    refreshData: {
      type: Function,
      required: true,
    },
  },
  computed: {
    taskData() {
      return this.$store.getters['tasks/dataItem']
    },
    dataItem() {
      return this.$store.getters['tasks/fastTaskItem']
    },
    supportDataItem() {
      return this.$store.getters['supports/dataItem']
    },
  },
}
</script>
